import './_JoinDiscord.scss';
import ImageDiscordLogoWhite from '../resources/Discord-Symbol-White.svg'
import ImageDiscordLogoBlurple from '../resources/Discord-Symbol-Blurple.svg'
// import React from 'react';
import React, { useEffect, useRef } from 'react';

export default function JoinDiscord() {
	const wrapperRef = useRef(null);
	const closeButtonRef = useRef(null);
  
	useEffect(() => {
	  const element = document.querySelector('.join-discord-wrapper');
  
	  if (element) {
		wrapperRef.current = element; // Store the element in the ref
  
		const timerId = setTimeout(() => {
		  if (wrapperRef.current) {
			wrapperRef.current.classList.remove('join-discord-wrapper--off-screen');
		  }
		}, 1500); // 1000 milliseconds = 1 second
  
		return () => {
		  clearTimeout(timerId); // Cleanup in case the component unmounts before the timeout
		};
	  }
	}, []);

	const handleClose = () => {
	  if (wrapperRef.current) {
		wrapperRef.current.classList.add('join-discord-wrapper--off-screen');
	  }
	};

	return (
		<div className="join-discord-wrapper join-discord-wrapper--off-screen">
			<div className="join-discord-inner">
				<h4 className="join-discord-title">
					Join our Discord
				</h4>
				<p className="join-discord-desc">
					Share your heroes, find a group, or just hang out.
				</p>
				<a className="button join-discord-button" href="https://discord.gg/NvBt8djsR7" target="_blank">
					<img className="discord-button-logo discord-button-logo--white" alt="Discord Logo" src={ImageDiscordLogoWhite} />
					<img className="discord-button-logo discord-button-logo--blurple" alt="Discord Logo" src={ImageDiscordLogoBlurple} />
					Join
				</a>
			</div>
			<a ref={closeButtonRef} className="join-discord-close" onClick={handleClose}>&times;</a>
		</div>
	);
}

